import * as React from 'react';
import classNames from 'classnames';
import { Button, Card, Col, Input, Row, notification } from 'antd';
import algoliasearch from 'algoliasearch';
import Typewriter from 'typewriter-effect';

import styles from './LandingPage.module.scss';

const algoliaClient = algoliasearch('WHF9BED03N', '3d5bd15806beac31b76e2069dddd3458');
const index = algoliaClient.initIndex('landing_v1_signup');

const codeSnippet = 
`<script src="https://doboundless.com/code/boundless.js"></script>
<script>
  Boundless.run({
    apiKey: '<your-api-key>',
  });
</script>
`;

const notifySuccessfulSignup = () => {
  notification.success({
    message: "You're signed up! 🎉",
    description:
      "We'll be in touch with you about the next steps 😊",
  });
};

const queries: string[] = [
  'Give me an area graph of departmental operating costs',
  'Show me a bar chart of our top 10 sales members this quarter',
  'Display a line graph of total tickets completed each month for the past year',
];

export const LandingPage: React.FC = () => {
  const [email, setEmail] = React.useState<string>('');

  function onEmailChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(e.target.value);
  }

  function onSubmit(e: any): boolean {
    e.preventDefault();
    if (!email) return false;

    const record = {
      email,
      createdAt: (new Date()).toUTCString(),
    };

    index.saveObject(record, {
      autoGenerateObjectIDIfNotExist: true,
    }).then(notifySuccessfulSignup);

    return false;
  }

  return (
    <div className={styles.LandingPage}>
      <Col xs={24} md={{ span: 16, offset: 4 }}>
        <section>
          <Row className={styles.LandingPage__banner} gutter={24}>
            <Col xs={24} md={{ span: 16 }}>
                <div className={styles.LandingPage__title}>Pecan</div>
                <div className={styles.LandingPage__tagline}>
                  AI generated charts and graphs
                </div>
                <Row>
                  <Col xs={24} md={20}>
                    <form className={styles.LandingPage__bannerForm} onSubmit={onSubmit}>
                      <Input
                        className={styles.LandingPage__bannerInput}
                        type="email"
                        onChange={onEmailChange}
                        value={email}
                        placeholder="adam@happyco.com"
                      />
                      <Button size="large" htmlType="submit" type="primary" disabled={!email}>Get Started</Button>
                    </form>
                  </Col>
                </Row>
            </Col>
            <Col className={styles.LandingPage__bannerContent} xs={24} md={{ span: 8 }}>
            </Col>
          </Row>
          <Row>
            <Col className={styles.LandingPage__typewriter}>
              <Typewriter
                options={{
                  autoStart: true,
                  deleteSpeed: 25,
                  delay: 50,
                  loop: true,
                  strings: queries,
                }}
              />
            </Col>
          </Row>
        </section>
      </Col>
    </div>
  );
}
