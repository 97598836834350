import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import { ImportImage } from 'src/components/ImportImage';

import styles from './NotFoundPage.module.scss';

export const NotFoundPage: React.FunctionComponent = function(): React.ReactElement {
  return (
    <div className={styles.NotFoundPage}>
      <div>
        Oh no, page not found
      </div>
      <div>
        <Link to="/">
          <Button type="primary">Go Home</Button>
        </Link>
      </div>
    </div>
  )
};
